import React from "react";
import homescreenimage1 from './Images/homescreen1.png';
import { useColors } from './ColorContext';

const HomeComponent1 = () => {
  const colors = useColors();

  return (

    <div style={{ backgroundColor: colors.background }}>
  <br />
  <div className="container">
    <div className="row align-items-center">
      <div className="col-sm order-0 order-sm-0">
        <h2 style={{ fontFamily: 'Boogaloo', color: colors.primary, textAlign: 'center' }}>
        Discover culinary delights at your fingertips - explore exceptional dining experiences from the comfort of your device!
        </h2>
        <div className="d-grid justify-content-center align-items-center">
        </div>
        <div className="text-center mb-5">
          <a type="button" className="btn btn-lg mx-1" href="/search" style={{ fontFamily: "Outfit", backgroundColor: colors.secondary, color: colors.background, fontWeight: 'bold' }}>
            Explore Restaurants
          </a>
        </div>
      </div>
      <div className="col-sm order-1 order-sm-1">
      <img src={homescreenimage1} className="img-fluid" alt="Pizza with basil leaves" />
        <br />
        <br />
      </div>
    </div>
  </div>
</div>

  );
};

export default HomeComponent1;
