import React, { useEffect, useState } from 'react';
import { db, auth } from '../config/firebase';
import { getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useColors } from './ColorContext';

const NavComponent = () => {
  const navigate = useNavigate();
  const colors = useColors();
  const { currentUser } = auth;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        setIsLoggedIn(true);
      } else {
        // User is logged out
        setIsLoggedIn(false);
      }
    });

    // Clean up the event listener when component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      // Fetch user data from Firestore if it exists
      const fetchUserData = async () => {
        const userDataRef = doc(db, 'userData', currentUser.uid);
        const userDataSnapshot = await getDoc(userDataRef);

        if (userDataSnapshot.exists()) {
          const userData = userDataSnapshot.data();
          setUserType(userData.userType);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  const logout = async () => {
    try {
      await signOut(auth);
      navigate('/logout');
    } catch (err) {
      // console.error(err);
    }
  };

  return (
    console.log(auth.currentUser),
    (
      <nav
        className="navbar fixed-top navbar-expand-lg px-md-5"
        style={{
          backgroundColor: colors.background,
          padding: "8px 0",
          lineHeight: "1",
        }}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              alt="logo"
              src="/full-logo.png"
              height="60"
              className="float-right"
            />
          </a>
          <button
            className="navbar-toggler border-0 ms-auto order-lg-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <i
              className="bi bi-list"
              aria-hidden="true"
              style={{ color: colors.primary, fontSize: "30px", fontWeight: "bolder" }}
            ></i>
          </button>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header d-flex justify-content-between align-items-center d-lg-none" style={{ backgroundColor: colors.background }}>
              <a className="navbar-brand" href="/">
                <img
                  alt="logo"
                  src="/full-logo.png"
                  height="60"
                  className="float-right"
                />
              </a>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className="offcanvas-body" style={{ backgroundColor: colors.background }}>
              <ul
                className="navbar-nav ms-lg-auto mb-2 mb-lg-0"
                style={{ fontWeight: "bold" }}
              >
                <style>
                  {`
        .nav-item a:hover {
          color: ${colors.secondary} !important;
          background-color: ${colors.background} !important;
          font-weight: bold !important;
        }
      `}
                </style>
                {isLoggedIn && (
                  <>
                    <style>
                      {`
        .nav-item.dropdown ul.dropdown-menu li a:hover {
          color: ${colors.secondary} !important;
          background-color: ${colors.white} !important;
          font-weight: bold !important;
        }
      `}
                    </style>
                    {isLoggedIn && userType === 'admin' && (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Admin
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="/a/businesses">
                              Review Businesses
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}


                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        My Account
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/orders">
                            My Orders
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/profile">
                            My Profile
                          </a>
                        </li>
                      </ul>
                    </li>

                    {isLoggedIn && (userType === 'admin' || userType === 'restaurant') && (
                      <li className="nav-item">
                        <a className="nav-link" href="/business/profiles">
                          Business Profiles
                        </a>
                      </li>
                    )}

                  </>
                )}
                {!isLoggedIn ? (
                  <>
                    <li className="nav-item">
                      <a className="nav-link" href="/login">
                        Login
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/signup">
                        Sign Up
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <a className="nav-link" href="#" onClick={logout}>
                        Logout
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    )
  );
};

export default NavComponent;

