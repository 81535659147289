import React, { useState, useEffect } from 'react';
import { db, auth } from '../config/firebase';
import { getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import NavComponent from './NavComponent';
import { useColors } from './ColorContext';

const UserProfileForm = () => {

  useEffect(() => {
    document.title = 'Your Profile | Wired Waiter';
  }
    , []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate("/login?redirectUrl=profile")
      }
    });
  }, []);

  const colors = useColors();
  const navigate = useNavigate();
  const { currentUser } = auth;
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [DynamicModalMessage, setDynamicModalMessage] = useState("");
  const [DynamicModalHeader, setDynamicModalHeader] = useState("");

  // Define a state variable to track whether all required fields are filled
  const [isFormValid, setIsFormValid] = useState(false);

  // Use useEffect to update the validity state whenever the input values change
  useEffect(() => {
    setIsFormValid(firstName !== '' && lastName !== '' && phoneNumber !== '');
  }, [firstName, lastName, phoneNumber]);

  const fetchUserData = (userId) => {
    setLoading(true);

    console.log("Fetching user data...");
    const userRef = doc(db, 'userData', userId);
    return getDoc(userRef)
      .then(userDoc => {
        console.log("User data fetched:", userDoc.data());
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFirstName(userData.firstName);
          setLastName(userData.lastName);
          setPhoneNumber(userData.phoneNumber);

        } else {
          console.error('User document does not exist');
          return Promise.resolve(); // Resolve the promise to continue the chain
        }
      })
      .catch(error => {
        console.error('Error fetching business data:', error);
      })
      .finally(() => {
        setLoading(false);
        console.log("Fetch operation completed.");
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("hi1")
        fetchUserData(user.uid);
        console.log("hi2")
      }
    });

    return () => unsubscribe(); // Unsubscribe from the listener when the component unmounts
  }, []);

  const handleSave = async () => {
    if (!currentUser) {
      return; // No current user, cannot save data
    }
    setLoading(true);
    // Create or update user data in Firestore
    const userDataRef = doc(db, 'userData', currentUser.uid);

    try {
      const userDataSnapshot = await getDoc(userDataRef);

      if (userDataSnapshot.exists()) {
        // User data exists, update it
        await updateDoc(userDataRef, {
          firstName,
          lastName,
          phoneNumber,
          email: currentUser.email
        });
      } else {
        // User data doesn't exist, create a new document
        await setDoc(userDataRef, {
          firstName,
          lastName,
          phoneNumber,
          email: currentUser.email
        });
      }

      // console.log('User data saved successfully.');
      const DynamicModalHeader = `Success`;
      setDynamicModalHeader(DynamicModalHeader);
      const modalMessage = `Your profile has been updated successfully.`;
      setDynamicModalMessage(modalMessage);
      setShowModal(true);
      setLoading(false);
    } catch (error) {
      // console.error('Error saving user data:', error);
      const DynamicModalHeader = `Error`;
      setDynamicModalHeader(DynamicModalHeader);
      const modalMessage = `An error occurred while updating your profile.`;
      setDynamicModalMessage(modalMessage);
      setShowModal(true);
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setDynamicModalMessage("");
  };

  return (
    <div>
      <NavComponent />
      <div className="container mt-5 pb-5" style={{ paddingTop: "85px" }}>
        <h2 className="mb-4">Your Profile</h2>
        <hr></hr>
        <form>
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
                required
              />
            </div>
          </div>
          <div className="row mb-5 pb-5">
            <div className="col-md-12 pt-2 pb-2">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
                required
              />
            </div>
          </div>
          {loading ? (
            <div
              disabled
              className="btn btn-lg"
              style={{
                fontWeight: 'bold',
                backgroundColor: colors.primary,
                color: colors.background,
                fontFamily: 'Outfit',
              }}
            >
              <span
                className="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span role="status"> Loading</span>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-lg"
              style={{
                fontWeight: 'bold',
                backgroundColor: colors.primary,
                color: colors.background,
                fontFamily: 'Outfit',
              }}
              onClick={handleSave}
              // Disable the button if the form is not valid
              disabled={!isFormValid}
            >
              Save
            </button>
          )}

        </form>
      </div>

      {showModal && (
        <div
          className="modal show"
          style={{ display: "block" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{
                  background: colors.primary,
                  color: colors.white,
                }}
              >
                <h5
                  className="modal-title"
                  style={{
                    background: colors.primary,
                    color: colors.white,
                    marginRight: "5px",
                  }}
                >
                  {DynamicModalHeader}
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  {DynamicModalMessage}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div
          className="modal-backdrop fade show"
          style={{ zIndex: 1040 }}
        ></div>
      )}
    </div>
  );
};

export default UserProfileForm;
