import React, { createContext, useContext } from 'react';

// Define the default colors object
const defaultColors = {
  primary: '#2E266D',
  secondary: '#E18B22',
  background: '#F7F7F2',
  lightGray: '#D3D3D3',
  darkGray: '#A9A9A9',
  black: '#232526',
  white: '#FFFFFF'
};

// Create the ColorContext and provide the default colors object
const ColorContext = createContext(defaultColors);

// Create a custom hook to access the colors from the context
export const useColors = () => useContext(ColorContext);

export default ColorContext;
