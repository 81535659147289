import React, { useState, useEffect } from "react";
import { useColors } from "./ColorContext";
import NavComponent from "./NavComponent";
import BottomFooter from "./BottomFooterStandard";
import { getFirestore, collection, addDoc, getDoc, doc, updateDoc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { getStorage, ref as storageRef,  uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../config/firebase";
import "./RichTextEditor.css"; // Import your custom CSS
import LoaderIcon from "./LoaderIcon";


const CreateBusinessAccounts = () => {
  const colors = useColors();
  const [organisationName, setOrganisationName] = useState("");
  const [businessUrl, setBusinessUrl] = useState("");
  const [errorMessageUrl, setErrorMessageUrl] = useState("");
  const [isUrlAvailable, setIsUrlAvailable] = useState(true); // Assume it's available by default
  const [loadingUrlAvailable, setLoadingUrlAvailable] = useState(false);
  const [organisationDescription, setOrganisationDescription] = useState("");
  const [organisationEmail, setOrganisationEmail] = useState("");
  const [organisationPhone, setOrganisationPhone] = useState("");
  // const [organisationVatNumber, setOrganisationVatNumber] = useState("");
  const [restaurantAddress, setRestaurantAddress] = useState("");
  const [restaurantCity, setRestaurantCity] = useState("");
  const [restaurantSuburb, setRestaurantSuburb] = useState("");
  const [restaurantProvince, setRestaurantProvince] = useState("");
  const [restaurantCountry, setRestaurantCountry] = useState("");
  const [restaurantPostalCode, setRestaurantPostalCode] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [DynamicErrorMessage, setDynamicErrorMessage] = useState("");
  const [isImageValid, setImageToBeValid] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [coverImage, setCoverImage] = useState(null);
  const [imageMetaData, setImageMetaData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [vatNumberError, setVatNumberError] = useState("");
  const [businessData, setBusinessData] = useState([]);
  const { value } = useParams();
  const [isUpdatePage, setIsUpdateScreen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [tiktokUrl, setTiktokUrl] = useState("");

  const [sundayOpen, setSundayOpen] = useState("");
  const [sundayClose, setSundayClose] = useState("");
  const [mondayOpen, setMondayOpen] = useState("");
  const [mondayClose, setMondayClose] = useState("");
  const [tuesdayOpen, setTuesdayOpen] = useState("");
  const [tuesdayClose, setTuesdayClose] = useState("");
  const [wednesdayOpen, setWednesdayOpen] = useState("");
  const [wednesdayClose, setWednesdayClose] = useState("");
  const [thursdayOpen, setThursdayOpen] = useState("");
  const [thursdayClose, setThursdayClose] = useState("");
  const [fridayOpen, setFridayOpen] = useState("");
  const [fridayClose, setFridayClose] = useState("");
  const [saturdayOpen, setSaturdayOpen] = useState("");
  const [saturdayClose, setSaturdayClose] = useState("");
  const [holidayOpen, setHolidayOpen] = useState("");
  const [holidayClose, setHolidayClose] = useState("");
  const [logoDownloadUrl, setLogoDownloadUrl] = useState("");

  const { currentUser } = auth;
  const storage = getStorage();
  const [uploadProgress, setUploadProgress] = useState(0);
  let continueOperation = true; // Flag to control whether to continue the create restaurant operation


  useEffect(() => {
    document.title = 'Create Your Business Account | Wired Waiter';
  }
    , []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate("/login?redirectUrl=business/create")
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (window.location.pathname.includes('/business/update')) {
          setIsUpdateScreen(true);
          setImageToBeValid(true);
          setFormStep(1);
        }
        else if (window.location.pathname.includes('/business/create')) {
          setFormStep(1);
        }
      } else {
        if (window.location.pathname.includes('/business/create')) {
          navigate("/login?redirectUrl=business/create");
        }
        else if (window.location.pathname.includes('/business/update')) {
          navigate("/login?redirectUrl=" + window.location.pathname.substring(1));
        }
      }
    });
  }, []);

  useEffect(() => {
    // Enable Bootstrap tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl, {
        template: '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
      });
    });

    return () => {
      // Clean up tooltips on component unmount
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, [formStep]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setOrganisationEmail(email);

    if (email === "" || isValidEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setOrganisationPhone(phone);

    // Remove any non-numeric characters from the input
    const numericPhone = phone.replace(/\D/g, "");

    if (numericPhone.length === 10) {
      // If the numericPhone is exactly 10 digits, format it as "### ### ####"
      const formattedPhone = numericPhone.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "$1 $2 $3"
      );
      setOrganisationPhone(formattedPhone);
      setPhoneError("");
    } else {
      setPhoneError("Please enter a valid phone number");
    }
  };

  async function checkUrlExists(url) {
    const db = getFirestore();
    const urlsCollection = collection(db, 'takenUrls');

    // Create a query against the collection.
    const q = query(urlsCollection, where('url', '==', url));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        console.log("URL already exists in the database:", url);
        return true; // URL exists
    } else {
        console.log("URL new to the database:", url);
        return false; // URL does not exist
    }
}

async function storeTakenUrl(url) {
  const db = getFirestore();
  const urlsCollection = collection(db, 'takenUrls');

  // Check if the URL already exists
  const urlExists = await checkUrlExists(url);
  if (!urlExists) {
      // If URL does not exist, store it
      await addDoc(urlsCollection, { url });
      continueOperation = true;
      console.log("URL added to the database:", url);
  } else {
      // URL already exists, alert user and stop further execution
      setIsUrlAvailable(false);
      continueOperation = false; // Set flag to false to stop further execution
      const errorMessage = `The URL you originally entered is no longer available. Please try a different one.`;
      setDynamicErrorMessage(errorMessage);
      setShowDeleteModal(true); 
      setFormStep(1); // Set form step back to the beginning
      window.scrollTo(0, 0); 
      return; // Stop further execution
  }
}

  const handleBusinessUrlBlur = async () => {
    setErrorMessageUrl('');
    setIsUrlAvailable(false);
    setLoadingUrlAvailable(true);

    const isValidInput = /^[a-zA-Z0-9-]*$/.test(businessUrl);

    if (!isValidInput) {
      setErrorMessageUrl(
        'Invalid character. Please enter only letters, numbers, or dashes.'
      );
      setIsUrlAvailable(false);
      setLoadingUrlAvailable(false);
    } else {
      // Check if URL is available
      const isAvailable = await checkUrlExists(businessUrl);

      if (!isAvailable) {
        setIsUrlAvailable(true); // URL is available
        setLoadingUrlAvailable(false);
        setErrorMessageUrl('');

      } else {
        setIsUrlAvailable(false); // URL is not available
        setLoadingUrlAvailable(false);
        setErrorMessageUrl('URL is not available. Please choose a different one.');
      }
    }
  };

  const handleBusinessUrlChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setBusinessUrl(inputValue);
  };

  // const handleVatNumberChange = (e) => {
  //   const vatNumber = e.target.value;
  //   setOrganisationVatNumber(vatNumber);

  //   // Remove any non-numeric characters from the input
  //   const numericVatNumber = vatNumber.replace(/\D/g, "");

  //   if (vatNumber !== numericVatNumber) {
  //     setVatNumberError("VAT number can only contain numbers.");
  //   } else if (numericVatNumber.length === 10 || numericVatNumber === "") {
  //     setVatNumberError("");
  //   } else {
  //     setVatNumberError("VAT number must be 10 digits (numbers only).");
  //   }
  // };

  const uploadImageToFirebase = (selectedImage, filePath) => {
    return new Promise((resolve, reject) => {
      const imageRef = storageRef(storage, 'logos/' + filePath + '/' + selectedImage.name);
      const uploadTask = uploadBytesResumable(imageRef, selectedImage, imageMetaData);
  
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
          setUploadProgress(progress); 
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // Error handling
          reject(error);
        },
        () => {
          // Upload completed successfully, get the download URL
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              console.log('File available at', downloadURL);
              resolve(downloadURL);
            })
            .catch((error) => {
              reject(error);
            });
        }
      );
    });
  };  

  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
  
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          if (img.width === img.height) {
            setCoverImage(selectedImage);
            setImageToBeValid(true);
          } else {
            const errorMessage = `Please ensure the image you are uploading is square!`;
            setDynamicErrorMessage(errorMessage);
            setShowDeleteModal(true);
            setImageToBeValid(false);
          }
        };
      };
      reader.readAsDataURL(selectedImage);
      // save image meta data to state
      setImageMetaData(selectedImage);
    }
  };

  // Check if the user is authenticated, and if not, redirect them to the login page
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        if (window.location.pathname.includes('/business/create')) {
          navigate("/login?redirectUrl=business/create");
        }
        else if (window.location.pathname.includes('/business/update')) {
          navigate("/login?redirectUrl=" + window.location.pathname.substring(1));
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const isCoverImageSquare = async () => {
    if (coverImage) {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(coverImage);

        img.onload = () => {
          if (img.width === img.height) {
            resolve(true); // Image is square
          } else {
            resolve(false); // Image is not square
          }
        };
      });
    } else {
      return false; // No cover image
    }
  };

  const handleContinue = () => {
    if (formStep === 1) {
      // Validate fields and sessions, then move to step 2
      if (
        organisationName &&
        organisationDescription && // Check if description is not empty
        organisationEmail &&
        organisationPhone &&
        isUrlAvailable &&
        businessUrl &&
        isCoverImageSquare() && // Check if cover image is square
        isImageValid
      ) {
        setFormStep(2);
        window.scrollTo(0, 0);
      } else {
        let missingFields = [];
        if (!businessUrl) missingFields.push("Website Url");
        if (!isUrlAvailable) missingFields.push("Valid/Available Website Url");
        if (!organisationName) missingFields.push("Name");
        if (!organisationDescription) missingFields.push("Description");
        if (!organisationEmail) missingFields.push("Email");
        if (!organisationPhone) missingFields.push("Phone");
        if (!isCoverImageSquare()) missingFields.push("Cover Image (must be square)");
        if (!isImageValid) missingFields.push("Logo (must be square)");

        const errorMessage = `Please ensure all required fields are entered: ${missingFields.join(", ")}`;
        setDynamicErrorMessage(errorMessage);
        setShowDeleteModal(true);
      }
    }
  };

  const validAddress = () => {
    let missingFields = [];
    if (!restaurantAddress) missingFields.push("Address");
    if (!restaurantSuburb) missingFields.push("Suburb");
    if (!restaurantCity) missingFields.push("City");
    if (!restaurantProvince) missingFields.push("Province");
    if (!restaurantCountry) missingFields.push("Country");
    if (!restaurantPostalCode) missingFields.push("Postal Code");

    if (missingFields.length === 0) {
      console.log("Address is valid.");
      return true;
    } else {
      const errorMessage = `The following fields are still required: ${missingFields.join(", ")}`;
      setDynamicErrorMessage(errorMessage);
      setShowDeleteModal(true);
      return false;
    }
  };

  const validTimes = () => {
    const daysOfWeek = [
      { name: "Sunday", open: sundayOpen, close: sundayClose },
      { name: "Monday", open: mondayOpen, close: mondayClose },
      { name: "Tuesday", open: tuesdayOpen, close: tuesdayClose },
      { name: "Wednesday", open: wednesdayOpen, close: wednesdayClose },
      { name: "Thursday", open: thursdayOpen, close: thursdayClose },
      { name: "Friday", open: fridayOpen, close: fridayClose },
      { name: "Saturday", open: saturdayOpen, close: saturdayClose },
      { name: "Holiday", open: holidayOpen, close: holidayClose },
    ];

    let invalidDays = [];

    daysOfWeek.forEach(day => {
      // Check if both open and close times are filled in
      if (day.open !== "" && day.close !== "") {
        // If open time is before close time, the day is valid
        if (day.open < day.close) {
          return;
        } else {
          // If open time is not before close time, the day is invalid
          invalidDays.push(day.name);
        }
      } else if (day.open !== "" || day.close !== "") {
        // If only one of open or close time is filled in, the day is invalid
        invalidDays.push(day.name);
      }
    });

    // Check if any day has a valid time range
    const isAnyDayValid = daysOfWeek.some(day => day.open !== "" && day.close !== "" && day.open < day.close);

    if (invalidDays.length === 0 && !isAnyDayValid) {
      // If no day is filled in correctly
      setDynamicErrorMessage("Before continuing, ensure that at least 1 complete day has been entered and the Open time is before the Close time for the days you have entered!");
      setShowDeleteModal(true);
      return false;
    }

    if (invalidDays.length === 0) {
      return true;
    } else {
      const errorMessage = `The following days have incorrect time ranges: ${invalidDays.join(", ")}`;
      setDynamicErrorMessage(errorMessage);
      setShowDeleteModal(true);
      return false;
    }
  };

  // Function to handle form submission
  const handleSubmitStep2 = () => {
    // Perform validation before submitting
    if (validAddress()) {
      // Proceed with form submission logic
      setFormStep(3);
      window.scrollTo(0, 0);
      console.log('step 2 submitted successfully');
    }
  };

  // Function to handle form submission
  const handleSubmitStep3 = () => {
    // Perform validation before submitting
    if (validTimes()) {
      // Proceed with form submission logic
      setFormStep(4);
      window.scrollTo(0, 0);
      console.log('step 3 submitted successfully');
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const createNewRestaurant = async () => {
    setLoading(true);
    const user = auth.currentUser;
  
    try {
      // add the business url to the realtime db
      await storeTakenUrl(businessUrl);

        // If URL exists, stop further execution
        if (!isUrlAvailable || !continueOperation) {
          setLoading(false);
          return;
      }
  
      // If URL is available, continue with further steps
      const restaurantRef = doc(db, 'currentRestaurantRefNumber', 'restaurantNumber');
      const restaurantSnapshot = await getDoc(restaurantRef);
      const restaurantDataFromFirestore = restaurantSnapshot.data();
      const theRestaurantNumberFromDB = restaurantDataFromFirestore.number;

      // Update the restaurant number in the restaurantNumbers collection
      const newRestaurantNumber = theRestaurantNumberFromDB + 1;
      await updateDoc(restaurantRef, { number: newRestaurantNumber });

      // Construct the order number with padding
      const paddedRestaurantNumber = newRestaurantNumber.toString().padStart(6, '0');

      console.log("assign roles to user");
      addUserAsOwner(`KK${paddedRestaurantNumber}`);

      // Upload the image to Firebase
      console.log("uploading the logo");
      const logoDownloadUrl = await uploadImageToFirebase(coverImage, `KK${paddedRestaurantNumber}`);
      console.log("Logo uploaded successfully:", logoDownloadUrl);

      // Construct the restaurant document reference
      const restRef = doc(db, 'restaurants', `KK${paddedRestaurantNumber}`);
      console.log("creating the restaurant");
  
      // Set the restaurant data 
      await setDoc(restRef, {
        restaurantNumber: `KK${paddedRestaurantNumber}`,
        lastUpdated: new Date(),
        creationTime: new Date(),
        coverImage: logoDownloadUrl,
        organisationName: organisationName,
        businessUrl: businessUrl,
        organisationDescription: organisationDescription,
        organisationEmail: organisationEmail,
        organisationPhone: organisationPhone,
        restaurantAddress: restaurantAddress,
        restaurantSuburb: restaurantSuburb,
        restaurantCity: restaurantCity,
        restaurantProvince: restaurantProvince,
        restaurantCountry: restaurantCountry,
        restaurantPostalCode: restaurantPostalCode,
        sundayOpen: sundayOpen,
        sundayClose: sundayClose,
        mondayOpen: mondayOpen,
        mondayClose: mondayClose,
        tuesdayOpen: tuesdayOpen,
        tuesdayClose: tuesdayClose,
        wednesdayOpen: wednesdayOpen,
        wednesdayClose: wednesdayClose,
        thursdayOpen: thursdayOpen,
        thursdayClose: thursdayClose,
        fridayOpen: fridayOpen,
        fridayClose: fridayClose,
        saturdayOpen: saturdayOpen,
        saturdayClose: saturdayClose,
        holidayOpen: holidayOpen,
        holidayClose: holidayClose,
        websiteUrl: websiteUrl,
        facebookUrl: facebookUrl,
        instagramUrl: instagramUrl,
        twitterUrl: twitterUrl,
        linkedInUrl: linkedInUrl,
        youtubeUrl: youtubeUrl,
        tiktokUrl: tiktokUrl
      });

      console.log("Done");
  
      // console.log('User data saved successfully.');
      setFormStep(6);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error creating restaurant:', error);
      setFormStep(7);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  // just a note for when yiu work on this again... 
  // try add the user ID to the document of the restaurant details 
  // then the owner will be linked direct, then do a select where 
  // from this collection to see ones the user owns, keep in mind 
  // how to do this for multiple owners though and also update how 
  // the saving logo rules will work

  const addUserAsOwner = async (restaurantID) => {
    // const userDocRef = doc(db, 'requestToCreateRestaurant', currentUser.uid); // problem child
    const userDocRef = doc(db, 'restaurantsOwnedByUser', currentUser.uid); // problem child
    // const restaurantDocRef = doc(db, 'ownersByRestaurant', restaurantID);

    try {
      setLoading(true);
      console.log('Fetching user data...');
      const userRestaurantData = await fetchUserData(userDocRef);
      console.log('User data fetched:', userRestaurantData);
  
      console.log('Creating request for new restaurant owner...');
      await requestToBeRestaurantOwner(userDocRef, restaurantID, userRestaurantData);
      console.log('Completed creating request for new restaurant owner.');
  
      // console.log('Requesting to be restaurant owner...');
      // await updateRestaurantOwnerData(restaurantDocRef, currentUser.uid);
      // console.log('Requesting to be restaurant owner completed.');

      // console.log('Updating user restaurant data...');
      // await updateUserRestaurantData(userDocRef, restaurantID, userRestaurantData);
      // console.log('User restaurant data updated.');
      
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchUserData = async (userDocRef) => {
    try {
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        console.log('User document exists:', userDocSnap.data());
        return userDocSnap.data();
      }
      console.log('User document does not exist.');
      return {};
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  };
  
  const requestToBeRestaurantOwner = async (userDocRef, restaurantID, userRestaurantData) => {
    try {
      userRestaurantData[restaurantID] = "pending";
      console.log('New user restaurant data:', userRestaurantData);
      await setDoc(userDocRef, userRestaurantData);
    } catch (error) {
      console.error('Error updating user restaurant data:', error);
      throw error;
    }
  };
  
  const updateRestaurantOwnerData = async (restaurantDocRef, userID) => {
    try {
      console.log('Setting restaurant owner data:', {
        [userID]: userID
      });
      await setDoc(restaurantDocRef, {
        [userID]: userID
      }, { merge: true });
    } catch (error) {
      console.error('Error updating restaurant owner data:', error);
      throw error;
    }
  };

  return (
    <div>
      <style jsx>
        {`

          .tooltip .tooltip-inner {
            background-color: ${colors.primaryDark} !important;
            color: ${colors.white} !important;
          }

          .form-check-input:checked {
            background-color: ${colors.primary};
            border-color: ${colors.primary};
          }

          .form-check-input:checked:focus {
            background-color: ${colors.primary};
            border-color: ${colors.primary};
          }

          .form-check-input:checked:hover {
            background-color: ${colors.primary};
            border-color: ${colors.primary};
          }

          /* Styles for the thumb (draggable handle) */
          input[type="range"]::-webkit-slider-thumb {
            background-color: ${colors.primary};
            border-color: ${colors.primary};
          }

          input[type="range"]::-moz-range-thumb {
            background-color: ${colors.primary};
            border-color: ${colors.primary};
          }

          input[type="range"]::-ms-thumb {
            background-color: ${colors.primary};
            border-color: ${colors.primary};
          }
        `}
      </style>
      <NavComponent />
      <div style={{ backgroundColor: colors.background, paddingTop: "60px" }}>
        <br />
        <div className="container container-page-creator">
          <div className="row align-items-center">
            <div className="col-sm mb-3">
              {formStep === 1 && (
                <div>
                  <h2
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    {!isUpdatePage && (
                      <>
                        Tell us about your restaurant
                      </>
                    )}
                    {isUpdatePage && (
                      <>
                        Update your restaurant info
                      </>
                    )}
                  </h2>
                </div>
              )}
              {formStep === 2 && (
                <div>
                  <h2
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    Where is your restaurant based?
                  </h2>
                  <h6 style={{ color: colors.black }}>
                    This is the address that customers will see on invoices, email notifications, and your website.
                  </h6><hr />
                </div>
              )}

              {formStep === 3 && (
                <div>
                  <h2
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    What are your operating hours?
                  </h2>
                  <h6 style={{ color: colors.black }}>
                    Select the opening and closing times of your store. Customers will see this on your website and ordering will be disabled outside of these hours.
                  </h6><hr />
                </div>
              )}

              {formStep === 4 && (
                <div>
                  <h2
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    Share your social media links on your profile
                  </h2>
                  <hr />
                </div>
              )}

              {formStep === 5 && (
                <div>
                  <h2 className="mt-3" style={{ fontFamily: "Boogaloo", color: colors.primary }}>
                    Review your restaurant
                  </h2>
                  <h4 style={{ color: colors.secondary }}>
                    If you need to make changes, click the back button below.
                  </h4>
                  <hr />
                  <div className="card" style={{ backgroundColor: colors.background, borderColor: colors.secondaryLighter }}>
                    <div className="card-body">
                      <div className="row">
                        <div className="">
                          <div
                            className="card image-preview mb-3"
                            style={{ maxWidth: "250px", maxHeight: "250px" }}
                          >
                            <img
                              src={coverImage ? URL.createObjectURL(coverImage) : businessData.coverImage}
                              alt="Preview"
                              className="img-thumbnail"
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title" style={{ color: colors.primary }}>Restaurant Name</h5>
                            <p className="card-text" style={{ color: colors.black }}>{organisationName}</p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Restaurant Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>wiredwaiter.co.za/menu/{businessUrl}</p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Description</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {organisationDescription}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Email</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {organisationEmail}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Phone</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {organisationPhone}
                            </p>
                            {/* <h5 className="card-title" style={{ color: colors.primary }}>VAT Number</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {organisationVatNumber || "N/A"}
                            </p> */}
                            <h5 className="card-title" style={{ color: colors.primary }}>Address</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {restaurantAddress + ", " + restaurantSuburb + ", " + restaurantCity + ", " + restaurantProvince + ", " + restaurantCountry + ", " + restaurantPostalCode}
                            </p>
                            <hr />
                            <h5 className="card-title" style={{ color: colors.primary }}>Operating Hours</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              Sunday: {sundayOpen && sundayClose ? `${sundayOpen} - ${sundayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Monday: {mondayOpen && mondayClose ? `${mondayOpen} - ${mondayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Tuesday: {tuesdayOpen && tuesdayClose ? `${tuesdayOpen} - ${tuesdayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Wednesday: {wednesdayOpen && wednesdayClose ? `${wednesdayOpen} - ${wednesdayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Thursday: {thursdayOpen && thursdayClose ? `${thursdayOpen} - ${thursdayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Friday: {fridayOpen && fridayClose ? `${fridayOpen} - ${fridayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Saturday: {saturdayOpen && saturdayClose ? `${saturdayOpen} - ${saturdayClose}` : "Closed"}
                            </p>
                            <p className="card-text" style={{ color: colors.black }}>
                              Public Holidays: {holidayOpen && holidayClose ? `${holidayOpen} - ${holidayClose}` : "Closed"}
                            </p>
                            <hr />
                            <h5 className="card-title" style={{ color: colors.primary }}>Website Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {websiteUrl || "N/A"}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Facebook Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {facebookUrl || "N/A"}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Instagram Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {instagramUrl || "N/A"}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Twitter Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {twitterUrl || "N/A"}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>LinkedIn Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {linkedInUrl || "N/A"}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>Youtube Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {youtubeUrl || "N/A"}
                            </p>
                            <h5 className="card-title" style={{ color: colors.primary }}>TikTok Url</h5>
                            <p className="card-text" style={{ color: colors.black }}>
                              {tiktokUrl || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  
                  {!loading && (
                    <>
                    <div className="mt-3 d-flex flex-wrap justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => { setFormStep(4); window.scrollTo(0, 0); }}
                    >
                      Go Back
                    </button>
                  </div>
                    </>
                  )}
                  {loading ? (
                    <div
                      className="btn mt-3"
                      disabled
                      style={{
                        color: colors.background,
                        backgroundColor: colors.primary,
                      }}
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span role="status"> Loading</span>
                    </div>
                  ) : (
                    <>
                      {!isUpdatePage && (
                        <>
                          <button
                            type="button"
                            className="btn mt-3"
                            style={{
                              color: colors.background,
                              backgroundColor: colors.primary,
                            }}
                            onClick={() => { createNewRestaurant() }}
                          >
                            Create Restaurant
                          </button>
                        </>
                      )}
                      {isUpdatePage && (
                        <>
                          <button
                            type="button"
                            className="btn mt-3"
                            style={{
                              color: colors.background,
                              backgroundColor: colors.primary,
                            }}
                            onClick={() => { setFormStep(6); window.scrollTo(0, 0); }}
                          >
                            Update Restaurant
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {formStep === 6 && (
                <div>
                  <h2
                    className="mt-5"
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.background,
                      textAlign: "center",
                    }}
                  >
                    You're good to go!
                  </h2>
                  <h4
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    {!isUpdatePage && (
                      <>
                        Your restaurant has been created successfully!
                      </>
                    )}
                    {isUpdatePage && (
                      <>
                        Your restaurant has been updated successfully!
                      </>
                    )}

                  </h4>

                  <div className="d-flex flex-column flex-md-row justify-content-center mt-5">
                    <a
                      href="/business/create"
                      className="btn mb-2 mb-md-0 mr-md-2"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.background,
                        marginRight: '5px',
                      }}
                    >
                      Create new restaurant
                    </a>
                    <a
                      href={`/menu/${businessUrl}`}
                      className="btn"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.background,
                        marginRight: '5px',
                      }}
                    >
                      Create Menu
                    </a>
                  </div>
                </div>
              )}

              {formStep === 7 && (
                <div>
                  <h2
                    className="mt-5"
                    style={{
                      fontFamily: "Boogaloo",
                      color: colors.background,
                      textAlign: "center",
                    }}
                  >
                    An Error Occurred!
                  </h2>
                  <h4
                    style={{
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    We encountered an issue processing your request. Please try again later. If the problem persists, kindly contact our support team for assistance. We apologize for any inconvenience.
                  </h4>
                  <div className="d-flex justify-content-center">
                    <button
                      style={{
                        color: colors.background,
                        backgroundColor: colors.primary,
                      }}
                      type="button"
                      className="btn mt-3"
                      onClick={() => navigate("/")}
                    >
                      Go Back Home
                    </button>
                  </div>
                </div>
              )}

              {formStep === 0 && (
                <div className="d-flex justify-content-center mt-5 mb-5 pb-5 pt-2">
                  <LoaderIcon />
                </div>
              )}

              {formStep === 1 && (
                <form onSubmit={handleContinue}>
                  <div className="mb-3">
                    <label
                      htmlFor="organisationName"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Restaurant Name <i
                        className="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Enter the name of your restaurant. This information will be displayed on your business profile."
                        style={{ cursor: 'pointer', color: colors.secondary }}
                      ></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="organisationName"
                      value={organisationName}
                      onChange={(e) => setOrganisationName(e.target.value)}
                      required
                    />
                  </div>
                  {!isUpdatePage && (
                    <>
                      <div className="mb-3">
                        <label
                          htmlFor="businessUrl"
                          className="form-label"
                          style={{ color: colors.primary }}
                        >
                          Your Restaurant Url Extension <i
                            className="bi bi-info-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Choose a URL for your menu page. Keep it simple, relevant, and reflective of your organization. The complete URL will be displayed below the input box when you start typing. You will not be able to change this once you create your restaurant."
                            style={{ cursor: 'pointer', color: colors.secondary }}
                          ></i>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="businessUrl"
                          value={businessUrl}
                          onChange={handleBusinessUrlChange}
                          onBlur={handleBusinessUrlBlur}
                          required
                        />
                        {loadingUrlAvailable && <div style={{ color: colors.black }}><div className="spinner-border spinner-border-sm" role="status">
                        </div> Checking Availability</div>}
                        {errorMessageUrl && <div style={{ color: colors.black }}>{errorMessageUrl}</div>}
                        {businessUrl && !errorMessageUrl && !loadingUrlAvailable && (
                          <p style={{ color: colors.primary }}>
                            wiredwaiter.co.za/menu/{businessUrl}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <div className="mb-3">
                    <label
                      htmlFor="organisationDescription"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Description <i
                        className="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="This description will be visible on your restaurant's page, helping users connect with the essence of your business."
                        style={{ cursor: 'pointer', color: colors.secondary }}
                      ></i>
                    </label>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="organisationDescription"
                        rows="5"
                        value={organisationDescription}
                        onChange={(e) =>
                          setOrganisationDescription(e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="OrganisationEmail"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Email <i
                        className="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="We use this email address for communication purposes with you. It will be visible on your restaurant's page."
                        style={{ cursor: 'pointer', color: colors.secondary }}
                      ></i>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${emailError ? "is-invalid" : ""
                        }`}
                      id="OrganisationEmail"
                      value={organisationEmail}
                      onChange={handleEmailChange}
                      required
                    />
                    {emailError && (
                      <div
                        className="invalid-feedback"
                        style={{ fontWeight: "bold", color: "#333333" }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="OrganisationPhone"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Phone <i
                        className="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="We use this phone number for communication purposes with you. It will be visible on your restaurant's page."
                        style={{ cursor: 'pointer', color: colors.secondary }}
                      ></i>
                    </label>
                    <input
                      type="tel"
                      className={`form-control ${phoneError ? "is-invalid" : ""
                        }`}
                      id="OrganisationPhone"
                      value={organisationPhone}
                      onChange={handlePhoneChange}
                      required
                    />
                    {phoneError && (
                      <div
                        className="invalid-feedback"
                        style={{ fontWeight: "bold", color: "#333333" }}
                      >
                        {phoneError}
                      </div>
                    )}
                  </div>
                  {/* <div className="mb-3">
                    <label
                      htmlFor="OrganisationVatNumber"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      VAT Number (Optional) <i
                        className="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Enter your VAT Number here if applicable. This information is optional. Providing your VAT Number may be required for tax purposes. Leave it blank if not applicable."
                        style={{ cursor: 'pointer', color: colors.secondary }}
                      ></i>
                    </label>
                    <input
                      type="tel"
                      className={`form-control ${vatNumberError ? "is-invalid" : ""
                        }`}
                      id="OrganisationVatNumber"
                      value={organisationVatNumber}
                      onChange={handleVatNumberChange}
                    />
                    {vatNumberError && (
                      <div
                        className="invalid-feedback"
                        style={{ fontWeight: "bold", color: "#333333" }}
                      >
                        {vatNumberError}
                      </div>
                    )}
                  </div> */}

                  <div className="mb-3">

                    {(coverImage ? URL.createObjectURL(coverImage) : businessData.coverImage) && (
                      <>
                        <label
                          className="form-label mt-3"
                          style={{ color: colors.primary }}
                        >
                          Logo Preview
                        </label>
                        {/* <div className="progress mb-3 col-lg-3 col-md-5">
  <div
    className="progress-bar"
    role="progressbar"
    style={{ width: `${uploadProgress}%`, backgroundColor: colors.primary, color: colors.secondary }}
    aria-valuenow={uploadProgress}
    aria-valuemin="0"
    aria-valuemax="100"
  >
    {uploadProgress}% Complete
  </div>
</div> */}
                        <div
                          className="card image-preview mb-3"
                          style={{ maxWidth: "250px", maxHeight: "250px" }}
                        >
                          <img
                            src={coverImage ? URL.createObjectURL(coverImage) : businessData.coverImage}
                            alt="Preview"
                            className="img-thumbnail"
                          />
                        </div>
                      </>
                    )}

                    {coverImage && (
                      <>

                        {!isUpdatePage && (
                          <>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                setCoverImage(null);
                                setImageToBeValid(false);
                              }}

                            >
                              Change Image
                            </button>
                          </>
                        )}
                        {isUpdatePage && (
                          <>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                setCoverImage(null);
                              }}

                            >
                              Change Image
                            </button>
                          </>
                        )}

                      </>
                    )}
                    {!coverImage && (
                      <>
                        <label
                          className="form-label mt-3"
                          style={{ color: colors.primary }}
                        >
                          Restaurant Logo (Square Only) <i
                            className="bi bi-info-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="This logo is what gets displayed on your restaurant's page to your users. We require it to be square to ensure uniformity across our platform."
                            style={{ cursor: 'pointer', color: colors.secondary }}
                          ></i>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="coverImageUpload"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                  </div>

                  <div className="mt-3 d-flex flex-wrap justify-content-between">
                    <button
                      style={{
                        color: colors.background,
                        backgroundColor: colors.primary,
                      }}
                      type="button"
                      className="btn"
                      onClick={() => handleContinue()}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              )}

              {formStep === 2 && (
                <form>
                  <div className="mb-3">
                    <label
                      htmlFor="restaurantAddress"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Street Name & Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="restaurantAddress"
                      value={restaurantAddress}
                      onChange={(e) => setRestaurantAddress(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="restaurantSuburb"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Suburb
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="restaurantSuburb"
                      value={restaurantSuburb}
                      onChange={(e) => setRestaurantSuburb(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="restaurantCity"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="restaurantCity"
                      value={restaurantCity}
                      onChange={(e) => setRestaurantCity(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="restaurantProvince"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Province
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="restaurantProvince"
                      value={restaurantProvince}
                      onChange={(e) => setRestaurantProvince(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="restaurantCountry"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="restaurantCountry"
                      value={restaurantCountry}
                      onChange={(e) => setRestaurantCountry(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="restaurantPostalCode"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Postal Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="restaurantPostalCode"
                      value={restaurantPostalCode}
                      onChange={(e) => setRestaurantPostalCode(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mt-3 d-flex flex-wrap justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={() => { setFormStep(1); window.scrollTo(0, 0); }} // Go back to step 2
                    >
                      Back to Step 1
                    </button>

                    <button
                      type="button"
                      className="btn"
                      style={{
                        color: colors.background,
                        backgroundColor: colors.primary,
                      }}
                      onClick={() => { handleSubmitStep2(); }}

                    >
                      Continue
                    </button>
                  </div>
                </form>
              )}

              {formStep === 3 && (
                <form>

                  <div className="mb-1">
                    <label htmlFor="sunday" className="form-label">Sunday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="sundayOpen" placeholder="Opening time" value={sundayOpen} onChange={(e) => setSundayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="sundayClose" placeholder="Closing time" value={sundayClose} onChange={(e) => setSundayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="monday" className="form-label">Monday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="mondayOpen" placeholder="Opening time" value={mondayOpen} onChange={(e) => setMondayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="mondayClose" placeholder="Closing time" value={mondayClose} onChange={(e) => setMondayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="tuesday" className="form-label">Tuesday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="tuesdayOpen" placeholder="Opening time" value={tuesdayOpen} onChange={(e) => setTuesdayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="tuesdayClose" placeholder="Closing time" value={tuesdayClose} onChange={(e) => setTuesdayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="wednesday" className="form-label">Wednesday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="wednesdayOpen" placeholder="Opening time" value={wednesdayOpen} onChange={(e) => setWednesdayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="wednesdayClose" placeholder="Closing time" value={wednesdayClose} onChange={(e) => setWednesdayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="thursday" className="form-label">Thursday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="thursdayOpen" placeholder="Opening time" value={thursdayOpen} onChange={(e) => setThursdayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="thursdayClose" placeholder="Closing time" value={thursdayClose} onChange={(e) => setThursdayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="friday" className="form-label">Friday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="fridayOpen" placeholder="Opening time" value={fridayOpen} onChange={(e) => setFridayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="fridayClose" placeholder="Closing time" value={fridayClose} onChange={(e) => setFridayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="Saturday" className="form-label">Saturday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="saturdayOpen" placeholder="Opening time" value={saturdayOpen} onChange={(e) => setSaturdayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="saturdayClose" placeholder="Closing time" value={saturdayClose} onChange={(e) => setSaturdayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="Holiday" className="form-label">Public Holiday</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Open</span>
                          <input type="time" className="form-control" id="holidayOpen" placeholder="Opening time" value={holidayOpen} onChange={(e) => setHolidayOpen(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">Close</span>
                          <input type="time" className="form-control" id="holidayClose" placeholder="Closing time" value={holidayClose} onChange={(e) => setHolidayClose(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 d-flex flex-wrap justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={() => { setFormStep(2); window.scrollTo(0, 0); }} // Go back to step 2
                    >
                      Back to Step 2
                    </button>

                    <button
                      type="button"
                      className="btn"
                      style={{
                        color: colors.background,
                        backgroundColor: colors.primary,
                      }}
                      onClick={() => { handleSubmitStep3(); }}

                    >
                      Continue
                    </button>
                  </div>
                </form>
              )}

              {formStep === 4 && (
                <form>
                  <div className="mb-3">
                    <label
                      htmlFor="eventAddress"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Website Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="websiteUrl"
                      value={websiteUrl}
                      onChange={(e) => setWebsiteUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="facebookUrl"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Facebook Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="facebookUrl"
                      value={facebookUrl}
                      onChange={(e) => setFacebookUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="InstagramUrl"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Instagram Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="instagramUrl"
                      value={instagramUrl}
                      onChange={(e) => setInstagramUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="twitterUrl"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Twitter Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="twitterUrl"
                      value={twitterUrl}
                      onChange={(e) => setTwitterUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="linkedinUrl"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      LinkedIn Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="linkedinUrl"
                      value={linkedInUrl}
                      onChange={(e) => setLinkedInUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="youtubeUrl"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      Youtube Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="youtubeUrl"
                      value={youtubeUrl}
                      onChange={(e) => setYoutubeUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="tiktokUrl"
                      className="form-label"
                      style={{ color: colors.primary }}
                    >
                      TikTok Url (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tiktokUrl"
                      value={tiktokUrl}
                      onChange={(e) => setTiktokUrl(e.target.value)}
                    />
                  </div>

                  <div className="mt-3 d-flex flex-wrap justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => { setFormStep(3); window.scrollTo(0, 0); }}
                    >
                      Go Back
                    </button>

                    <button
                      type="button"
                      className="btn"
                      style={{
                        color: colors.background,
                        backgroundColor: colors.primary,
                      }}
                      onClick={() => { setFormStep(5); window.scrollTo(0, 0); }}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              )}

              {showDeleteModal && (
                <div
                  className="modal show"
                  style={{ display: "block" }}
                  tabIndex="-1"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{
                          background: colors.primary,
                          color: colors.white,
                        }}
                      >
                        <h5
                          className="modal-title"
                          style={{
                            background: colors.primary,
                            color: colors.white,
                            marginRight: "5px",
                          }}
                        >
                          Error!!
                        </h5>
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          onClick={closeDeleteModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p>
                          {DynamicErrorMessage}
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeDeleteModal}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showDeleteModal && (
                <div
                  className="modal-backdrop fade show"
                  style={{ zIndex: 1040 }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
    </div>
  );
};

export default CreateBusinessAccounts;
