import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ColorContext from './components/ColorContext';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import LogoutPage from './components/LogoutPage';
import UserProfileForm from './components/UserProfileForm';
import ViewBusinesses from './components/ViewBusinesses';
import ForgotPassword from './components/ForgotPassword';
import NotFoundPage from './components/NotFound';
import CreateBusinessAccounts from './components/CreateBusinessAccounts';
import WhyUs from './components/faq';
import TodoWithDemi from './components/TodoWithDemi';


function App() {
  const colors = {
    primary: '#2E266D',
    primaryLight: '#5C53B9',
    secondary: '#E18B22',
    secondaryLight: '#F4C279',
    background: '#F7F7F2',
    lightGray: '#D3D3D3',
    darkGray: '#A9A9A9',
    black: '#232526',
    white: '#FFFFFF'
  };

  return (
    <ColorContext.Provider value={colors}>
    <Router>
      <Routes>
        <Route element={<HomePage />} path="/" />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<SignUpPage />} path="/signup" />
        <Route element={<LogoutPage />} path="/logout" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<UserProfileForm />} path="/profile" />
        <Route element={<ViewBusinesses />} path="/business/profiles" />
        <Route element={<CreateBusinessAccounts />} path="/business/create" />
        <Route element={<TodoWithDemi />} path="/todo-today" />
        
        {/* <Route element={<WhyUs />} path="/why-us" /> */}
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </Router>
    </ColorContext.Provider>
  );
}

export default App;
