import React, { useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useColors } from './ColorContext';

const ForgotPasswordPage = () => {
  const colors = useColors();
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    document.title = "Forgot Password | Wired Waiter";
  }, []);

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset email sent! Check your inbox.');
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(err.message);
      setSuccessMessage('');
    }
  };

  const handleDismiss = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <div style={{ backgroundColor: colors.background }}>
      <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <div className="card" style={{ width: "40rem", border: 'none' }}>
          <div className="card-body">
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src="full-logo-white-bg.png"
                height="90"
                className="float-right"
              />
            </div>
            <h5 className="card-title" style={{ fontFamily: "Outfit", color: colors.black, textAlign: "center", fontSize: "30px" }}>
              Forgot Password
            </h5>
            {errorMessage && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {errorMessage}
                <button type="button" className="btn-close" aria-label="Close" onClick={handleDismiss}></button>
              </div>
            )}
            {successMessage && (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                {successMessage}
                <button type="button" className="btn-close" aria-label="Close" onClick={handleDismiss}></button>
              </div>
            )}
            <div>
              <div className="mb-3" style={{ fontFamily: "Outfit", color: colors.black }}>
                <label htmlFor="email" className="form-label">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="invalid-feedback" style={{ fontFamily: "Outfit" }}>
                  Please enter a valid email address.
                </div>
              </div>
              <center>
                <button
                  type="button"
                  className="btn btn-lg"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: colors.primary,
                    color: colors.background,
                    fontFamily: "Outfit",
                  }}
                  onClick={handleResetPassword}
                >
                  RESET PASSWORD
                </button>
              </center>
            </div>
            <div className="my-3 text-center" style={{ fontFamily: "Outfit" }}>
              <div>
                Remember your password?
                <a href="/login" style={{ color: colors.secondary, textDecoration: "none" }}> Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
