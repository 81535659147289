import React, { useEffect, useState } from "react";
import { useColors } from './ColorContext';
import BottomFooter from "./BottomFooterStandard";


const BottomMobileNav = () => {
  const colors = useColors();

  const [isMobileDevice, setIsMobileDevice] = useState(
    window.innerWidth <= 425
  );

  // Add an event listener to handle screen width changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 425);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once during component mount


  return (
    <>
      {/* {isMobileDevice && (
        <div className="col-md-12" style={{ marginBottom: '60px' }}>
          <div
            className="fixed-bottom"
            style={{
              backgroundColor: colors.background,
              paddingTop: "5px",
              paddingBottom: "5px",
              display: "flex",
              justifyContent: "space-around",
              borderTop: "1px solid #ccc",
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 1000,
            }}
          >

            <a href="/search" style={{ textDecoration: 'none' }}>
              <div style={{ textAlign: "center", color: colors.primary, fontWeight: "bold" }}>
                <i className="bi bi-search" style={{ fontSize: "24px" }}></i>
                <p style={{ margin: 0, fontSize: "12px" }}>Search</p>
              </div>
            </a>

            <a href="/orders" style={{ textDecoration: 'none' }}>
              <div style={{ textAlign: "center", color: colors.primary, fontWeight: "bold" }}>
                <i className="bi bi-heart" style={{ fontSize: "24px" }}></i>
                <p style={{ margin: 0, fontSize: "12px" }}>Orders</p>
              </div>
            </a>

            <a href="/profile" style={{ textDecoration: 'none' }}>
              <div style={{ textAlign: "center", color: colors.primary, fontWeight: "bold" }}>
                <i className="bi bi-person-circle" style={{ fontSize: "24px" }}></i>
                <p style={{ margin: 0, fontSize: "12px" }}>Profile</p>
              </div>
            </a>
          </div>
        </div>
      )}
      {!isMobileDevice && (
        <BottomFooter />
      )} */}

<BottomFooter />
    </>
  );
};

export default BottomMobileNav;