import React from "react";
import homescreenimage2 from './Images/homescreen2.jpeg';
import { useColors } from './ColorContext';

const HomeComponent2 = () => {
  const colors = useColors();

  return (

    <div style={{ backgroundColor: colors.primary }}>
  <br />
  <div className="container">
    <div className="row align-items-center">
      <div className="col-sm order-1 order-sm-0">
      <img style={{ borderRadius: '15px 50px 30px' }} src={homescreenimage2} className="img-fluid" alt="2 People eating fried chips and a beef burger together" />
        <br />
        <br />
      </div>
      <div className="col-sm order-0 order-sm-1">
      <h2 style={{ fontFamily: 'Boogaloo', color: colors.secondary, textAlign: 'center' }}>
      Can we take your order please?
        </h2>
        <div className="d-grid justify-content-center align-items-center">
        </div>
        <p style={{ fontFamily: "Outfit", color: colors.background, textAlign: "center" }}>
        Experience the ultimate convenience of ordering food online or directly from your table. Browse menus, place orders, and pay seamlessly - whether you're at home, in the office, or dining in. No more waiting for someone to take your order or payment. Take control of your dining experience from start to finish.
        </p>
        <div className="d-grid justify-content-center align-items-center">
        </div>
        <div className="text-center mb-5">
        <a type="button" className="btn btn-lg mx-1" href="/search" style={{ fontFamily: "Outfit", backgroundColor: colors.background, color: colors.primary, fontWeight: 'bold' }}>
            Get Started
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default HomeComponent2;
