import React, { useEffect, useState } from "react";
import BottomMobileNav from "./BottomMobileNav";
import NavComponent from "./NavComponent";
import { useNavigate } from "react-router-dom";
import { auth, db } from '../config/firebase';
import { getDoc, doc } from 'firebase/firestore';
import { useColors } from "./ColorContext";
import "./RichTextEditor.css"; // Import your custom CSS
import LoaderIcon from "./LoaderIcon";

const ViewBusinesses = () => {
  const navigate = useNavigate();
  const colors = useColors();
  const [businessData, setBusinessData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const [showTeamRequestsInvite, setShowTeamRequestsInvite] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const isInviteButtonDisabled = selectedRole === "";
  const [emailError, setEmailError] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);

  const fetchBusinessData = (userId) => {
    setLoading(true);

    console.log("Fetching user data...");
    const userRef = doc(db, 'restaurantsOwnedByUser', userId);
    return getDoc(userRef)
        .then(userDoc => {
            console.log("User data fetched:", userDoc.data());
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const restaurantIds = Object.keys(userData);
                console.log("All Restaurant IDs:", restaurantIds);

                // Fetch details of each restaurant from the "restaurants" collection
                const restaurantDetailsPromises = restaurantIds.map(restaurantId => {
                    console.log(`Fetching details for restaurant with ID ${restaurantId}...`);
                    const restaurantRef = doc(db, 'restaurants', restaurantId);
                    return getDoc(restaurantRef)
                        .then(restaurantDoc => {
                            if (restaurantDoc.exists()) {
                                console.log(`Details fetched for restaurant with ID ${restaurantId}:`, restaurantDoc.data());
                                return { id: restaurantId, data: restaurantDoc.data() };
                            } else {
                                console.error(`Restaurant with ID ${restaurantId} does not exist`);
                                return null;
                            }
                        });
                });

                // Wait for all restaurant details to be fetched
                return Promise.all(restaurantDetailsPromises)
                    .then(restaurantDetails => {
                        console.log("Restaurant Details:", restaurantDetails);
                        setBusinessData(restaurantDetails);
                        console.log("Business data set successfully.");
                        // Do something with the restaurant details, such as updating state or displaying them
                    });
            } else {
                console.error('User document does not exist');
                return Promise.resolve(); // Resolve the promise to continue the chain
            }
        })
        .catch(error => {
            console.error('Error fetching business data:', error);
        })
        .finally(() => {
            setLoading(false);
            console.log("Fetch operation completed.");
        });
};

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("hi1")
        fetchBusinessData(user.uid);
        console.log("hi2")
      } else {
        // user is not logged in 
        navigate("/login?redirectUrl=business/profiles");
      }
    });
  
    return () => unsubscribe(); // Unsubscribe from the listener when the component unmounts
  }, []);
  

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);

    if (email === "" || isValidEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const showInviteTab = () => {
    setShowTeamMembers(false);
    setShowTeamRequestsInvite(true);
  };

  const showTeamMembersTab = () => {
    setShowTeamRequestsInvite(false);
    setShowTeamMembers(true);
  };

  // Function to handle the delete action
  const handleDelete = () => {
    // Perform the delete action here
    // You can call an API or delete the item from your data source
    // After the delete action is complete, close the modal
    closeDeleteModal();
  };

  const openTeamModal = () => {
    setShowTeamMembers(true);
    setShowTeamRequestsInvite(false);
    setShowTeamModal(true);
  };

  const closeTeamModal = () => {
    setShowTeamModal(false);
  };

  const handleInviteToTeam = () => {
    closeTeamModal();
  };

  const openPaymentsModal = () => {
    setShowPaymentsModal(true);
  };

  const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    // You can send the collected data to your server or perform other actions
  };

  return (
    console.log(businessData),
    (
      <div style={{ backgroundColor: colors.background, minHeight: '100vh' }}>
        <NavComponent />
        <div
          className="container container-page-creator mt-5"
          style={{ paddingTop: "40px" }}
        >
          <h2 style={{ color: colors.primary, fontWeight: "bold" }}>
            Your business accounts
          </h2>
          <p>
            {" "}
            <p className="mt-2" style={{ fontWeight: "bold" }}>
            Explore and manage your business profiles here. Each business profile represents a single restaurant location. If you have multiple branches, you will need a separate business profile for each one. Whether you're creating a new profile or updating an existing one, you can do it all right here.
            </p>
            <div className="d-flex flex-column flex-md-row">
              <a
                href="/business/create"
                className="btn mb-2 mb-md-0 mr-md-2"
                style={{
                  backgroundColor: colors.secondary,
                  color: colors.background,
                  marginRight: '5px',
                }}
              >
                <i className="bi bi-building-fill-add"></i> Create a new business profile
              </a>

              <button
                className="btn mb-2 mb-md-0 mr-md-2"
                style={{
                  background: colors.primary,
                  color: colors.background,
                  textAlign: 'center',
                }}
                onClick={openTeamModal}
              >
                <i className="bi bi-envelope-plus-fill"></i> Invites
              </button>
            </div>

            <hr />
            {loading ? (
              <div className="d-flex justify-content-center mt-5 mb-5 pb-5 pt-2">
                <LoaderIcon />
              </div>
            ) : formStep === 1 ? (
              <>
                <div>
                  <h2
                    className="mt-5"
                    style={{
                      color: colors.black,
                      textAlign: "center",
                    }}
                  >
                    An Error Occurred!
                  </h2>
                  <h4
                    style={{
                      color: colors.primary,
                      textAlign: "center",
                    }}
                  >
                    We can't show your businesses right now. Please try again later.
                  </h4>
                </div>
              </>
            ) : formStep === 0 && businessData.length === 0 ? (
              <>
                <h2
                  className="mb-5 text-center"
                  style={{ marginTop: "50px", paddingBottom: "90px" }}
                >
                  There are no businesses linked to your account at the moment.
                </h2>
              </>
            ) : (
              <>
                <div className="mb-2 d-flex justify-content-center">
                  <div>
                    <div className="card h-100 mx-auto" style={{ border: 'none' }}>
                      <div className="card-body">
                        <div className="table-responsive ">
                          <table
                            className="table table-borderless table-hover"
                            style={{ color: colors.black }}
                          >
                            <tbody>
                              {businessData.map((business, index) => (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={business.data.coverImage}
                                      alt="profile"
                                      style={{ width: "50px", borderRadius: "15%" }}
                                    />
                                  </td>
                                  <td>{business.data.organisationName}</td>
                                  <td>
                                    <div className="d-flex flex-column flex-md-row gap-0 column-gap-3">
                                      <a
                                        href={`/menu/${business.data.businessUrl}`}
                                        className="btn btn-sm mb-3 d-flex flex-column align-items-center"
                                        style={{
                                          background: colors.secondaryLight,
                                          color: colors.black,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <i className="bi bi-eye"></i>
                                        <span>View Menu</span>
                                      </a>


                                      <a
                                        href={`/business/events/${business.data.businessUrl}`}
                                        className="btn btn-sm mb-3 d-flex flex-column align-items-center"
                                        style={{
                                          background: colors.secondaryLight,
                                          color: colors.black,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <i className="bi bi-calendar4-week"></i> Manage Menu
                                      </a>

                                      <button
                                        className="btn btn-sm mb-3 d-flex flex-column align-items-center"
                                        style={{
                                          background: colors.secondaryLight,
                                          color: colors.black,
                                          textAlign: 'center',
                                        }}
                                        onClick={openTeamModal}
                                      >
                                        <i className="bi bi-people"></i> Manage Team
                                      </button>

                                      <a
                                        href={`/business/update-account/${business.data.businessUrl}`}
                                        className="btn btn-sm mb-3 d-flex flex-column align-items-center"
                                        style={{
                                          background: colors.secondaryLight,
                                          color: colors.black,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <i className="bi bi-pencil-square"></i> Edit Business
                                      </a>

                                      <button
                                        className="btn btn-sm mb-3 d-flex flex-column align-items-center"
                                        style={{
                                          background: colors.secondaryLight,
                                          color: colors.black,
                                          textAlign: 'center',
                                        }}
                                        onClick={openPaymentsModal}
                                      >
                                        <i className="bi bi-cash"></i>Specify Payments
                                      </button>

                                      <button
                                        className="btn btn-sm mb-3 d-flex flex-column align-items-center"
                                        style={{
                                          background: colors.secondaryLight,
                                          color: colors.black,
                                          textAlign: 'center',
                                        }}
                                        onClick={openDeleteModal}
                                      >
                                        <i className="bi bi-trash"></i> Delete
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {showDeleteModal && (
              <div
                className="modal show"
                style={{ display: "block" }}
                tabIndex="-1"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{
                        background: colors.primary,
                        color: colors.white,
                      }}
                    >
                      <h5
                        className="modal-title"
                        style={{
                          background: colors.primary,
                          color: colors.white,
                          marginRight: "5px",
                        }}
                      >
                        Confirm Delete
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        onClick={closeDeleteModal}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to delete this business?</p>
                      <p>
                        Doing so will delete all the data assosiated with this
                        business including events and attendee details (you
                        can't delete a business if you have active events).
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeDeleteModal}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleDelete}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showDeleteModal && (
              <div
                className="modal-backdrop fade show"
                style={{ zIndex: 1040 }}
              ></div>
            )}
            {showTeamModal && (
              <div
                className="modal show"
                style={{ display: "block" }}
                tabIndex="-1"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{
                        background: colors.primary,
                        color: colors.white,
                      }}
                    >
                      <h5
                        className="modal-title"
                        style={{
                          background: colors.primary,
                          color: colors.white,
                          marginRight: "5px",
                        }}
                      >
                        Manage Team Members
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        onClick={closeTeamModal}
                      ></button>
                    </div>
                    {showTeamMembers && (
                      <>
                        <ul className="nav justify-content-center">
                          <li className="nav-item">
                            <a
                              className="btn mt-2"
                              style={{
                                background: colors.primary,
                                color: colors.white,
                                marginRight: "5px",
                              }}
                            >
                              My Team
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="btn mt-2"
                              style={{
                                background: colors.white,
                                color: colors.primary,
                                marginRight: "5px",
                              }}
                              onClick={() => showInviteTab()}
                            >
                              Invite
                            </a>
                          </li>
                        </ul>
                        <div className="modal-body">
                          <p>Here you can manage your team members.</p>
                          <>
                            <div
                              className="input-group mb-2"
                              style={{ color: colors.darkGray }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                              />
                              <button
                                className="btn"
                                type="button"
                                style={{
                                  backgroundColor: colors.lightGray,
                                  color: colors.primary,
                                }}
                              >
                                <i className="bi bi-search"></i>
                              </button>
                            </div>
                            <div className="table-responsive ">
                              <table
                                className="table table-borderless table-hover"
                                style={{ color: colors.black }}
                              >
                                <thead>
                                  <tr>
                                    <th style={{ color: colors.secondary }}>
                                      Join Date
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Name
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Surname
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Role
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Email
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Phone
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Status
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Options
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {businessData.map((business, index) => (
                                    <tr key={index}>
                                      <th>{business.creationDateTime}</th>
                                      <th>{business.name}</th>
                                      <th>{business.name}</th>
                                      <th>{business.name}</th>
                                      <th>{business.name}</th>
                                      <th>{business.name}</th>
                                      <th>{business.name}</th>
                                      <th>
                                        <button
                                          className="btn btn-sm mb-3"
                                          style={{
                                            background: colors.black,
                                            color: colors.white,
                                          }}
                                        >
                                          <i className="bi bi-trash"></i>{" "}
                                          Remove
                                        </button>
                                      </th>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </div>
                      </>
                    )}
                    {showTeamRequestsInvite && (
                      <>
                        <ul className="nav justify-content-center">
                          <li className="nav-item">
                            <a
                              className="btn mt-2"
                              style={{
                                background: colors.white,
                                color: colors.primary,
                                marginRight: "5px",
                              }}
                              onClick={() => showTeamMembersTab()}
                            >
                              My Team
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="btn mt-2"
                              style={{
                                background: colors.primary,
                                color: colors.white,
                                marginRight: "5px",
                              }}
                            >
                              Invite
                            </a>
                          </li>
                        </ul>
                        <div className="modal-body">
                          <p>
                            Here you can invite new members to your team. You
                            can invite new members by entering their email
                            address.
                          </p>
                          <>
                            <div className="mb-3" style={{ color: colors.darkGray }}>
                              <div className="mb-2">
                                <input
                                  type="email"
                                  className={`form-control ${emailError ? "is-invalid" : ""
                                    }`}
                                  id="UserEmail"
                                  value={UserEmail}
                                  onChange={handleEmailChange}
                                  required
                                />
                                {emailError && (
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#333333",
                                    }}
                                  >
                                    {emailError}
                                  </div>
                                )}
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select"
                                  onChange={handleRoleChange}
                                  value={selectedRole}
                                >
                                  <option value="">Specify Role</option>
                                  <option value="option1">Owner</option>
                                  <option value="option2">Door Scanner</option>
                                  <option value="option3">Editor</option>
                                  <option value="option4">Viewer</option>
                                  <option value="option5">Guest</option>
                                  {/* Add more options as needed */}
                                </select>
                              </div>
                              <div>
                                <button
                                  className="btn"
                                  type="button"
                                  style={{
                                    backgroundColor: colors.lightGray,
                                    color: colors.primary,
                                  }}
                                  disabled={isInviteButtonDisabled || emailError}
                                >
                                  Send Invite
                                </button>
                              </div>
                            </div>
                            <hr />
                            <div className="table-responsive ">
                              <table
                                className="table table-borderless table-hover"
                                style={{ color: colors.black }}
                              >
                                <thead>
                                  <tr>
                                    <th style={{ color: colors.secondary }}>
                                      Invite Date
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Role
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Email
                                    </th>
                                    <th style={{ color: colors.secondary }}>
                                      Options
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {businessData.map((business, index) => (
                                    <tr key={index}>
                                      <th>{business.creationDateTime}</th>
                                      <th>{business.name}</th>
                                      <th>{business.name}</th>
                                      <th>
                                        <button
                                          className="btn btn-sm mb-3"
                                          style={{
                                            background: colors.black,
                                            color: colors.white,
                                          }}
                                        >
                                          <i className="bi bi-trash"></i>{" "}
                                          Remove
                                        </button>
                                      </th>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {showTeamModal && (
              <div
                className="modal-backdrop fade show"
                style={{ zIndex: 1040 }}
              ></div>
            )}
            {showPaymentsModal && (
              <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{ background: colors.primary, color: colors.white }}
                    >
                      <h5
                        className="modal-title"
                        style={{
                          background: colors.primary,
                          color: colors.white,
                          marginRight: '5px',
                        }}
                      >
                        Specify Payout Details
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white "
                        onClick={closePaymentsModal}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="accountHolder" className="form-label">
                            Account Holder Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="accountHolder"
                            value={accountHolder}
                            onChange={(e) => setAccountHolder(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="accountNumber" className="form-label">
                            Account Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="accountNumber"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="bankName" className="form-label">
                            Bank Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bankName"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="branchCode" className="form-label">
                            Branch Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="branchCode"
                            value={branchCode}
                            onChange={(e) => setBranchCode(e.target.value)}
                            required
                          />
                        </div>

                        <div>
                          <ul className="form-bullets">
                            <li>Your event earnings will be processed as a lump sum <strong>24 hours</strong> after it concludes or on a weekly basis for recurring events.</li>
                            <li>Your event earnings will be deposited automatically into the bank account specified above.</li>
                            <li>Each payout is subject to a fee of <strong>R10</strong> each.</li>
                            <li>You may get informed that a hold of <strong>10%</strong> or more of your earnings may be held for up to 2 weeks to process refund requests, chargebacks, and other incurred expenses.</li>
                          </ul>
                        </div>

                        <button type="button" className="btn"
                          style={{
                            background: colors.primary,
                            color: colors.white,
                            marginRight: "5px",
                          }}
                          onClick={closePaymentsModal}
                        >
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showPaymentsModal && (
              <div
                className="modal-backdrop fade show"
                style={{ zIndex: 1040 }}
              ></div>
            )}
          </p>
        </div>
        <BottomMobileNav />
      </div>
    )
  );
};

export default ViewBusinesses;
