import React, {useEffect} from 'react';
import { auth } from '../config/firebase';
import { useColors } from './ColorContext';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {

  useEffect(() => {
    document.title = "Logout | Wired Waiter";
  }, []);

  const colors = useColors();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (err) {
      // console.error(err);
    }
  };

  return (
    <div style={{ backgroundColor: colors.background }}>
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh', borderColor: colors.gray }}>
        <div className="card" style={{ width: '40rem', border: 'none' }}>
          <div className="card-body">
          <div style={{display:"flex",
  justifyContent:"center",
  alignItems:"center"}}>
            <img
                alt="logo"
                src="full-logo-white-bg.png"
                height="90"
                className="float-right"
              />
          </div>  
            <h5 className="card-title mt-3 mb-3" style={{ fontFamily: 'Outfit', color: colors.black, textAlign: 'center', fontSize: '30px' }}>
              You have been logged out
            </h5>
            <center>
              <button
                type="button"
                className="btn"
                style={{ fontWeight: 'bold', backgroundColor: colors.primary, color: colors.background, fontFamily: 'Outfit' }}
                onClick={handleLogout}
              >
                LOG IN AGAIN
              </button>
            </center>
            <center>
              <button
                type="button"
                className="btn mt-3"
                style={{ fontWeight: 'bold', backgroundColor: colors.secondary, color: colors.background, fontFamily: 'Outfit' }}
                onClick={() => navigate('/')}
              >
                BACK TO HOME
              </button>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
