import React, { useState, useEffect, useRef } from 'react';
import { database } from '../config/firebase';
import { ref, push, update, remove, onValue } from "firebase/database";
import { useColors } from './ColorContext';
import { useNavigate } from 'react-router-dom';
import autoAnimate from '@formkit/auto-animate';

const TodoWithDemi = () => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState("");
    const colors = useColors();
    const navigate = useNavigate();

    // Use a ref for auto-animate
    const parentRef = useRef(null);

    useEffect(() => {
        document.title = "TodoWithDemi";
        const todosRef = ref(database, 'todos');

        const unsubscribe = onValue(todosRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const loadedTodos = Object.entries(data).map(([id, todo]) => ({ id, ...todo }));
                loadedTodos.sort((a, b) => a.order - b.order);
                setTodos(loadedTodos);
            } else {
                setTodos([]);
            }
        });

        return () => unsubscribe();
    }, []);

    // Initialize auto-animate on the parentRef
    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef]);

    const handleAddTodo = () => {
        if (newTodo.trim()) {
            const newTodoRef = push(ref(database, 'todos'));
            update(newTodoRef, {
                text: newTodo,
                completed: false,
                order: todos.length,
            });
            setNewTodo("");
        }
    };

    const handleToggleComplete = (id, completed) => {
        const todoRef = ref(database, `todos/${id}`);
        update(todoRef, { completed: !completed });
    };

    const handleDeleteTodo = (id) => {
        const todoRef = ref(database, `todos/${id}`);
        remove(todoRef);
    };

    const handleUpdateTodo = (id, newText) => {
        const todoRef = ref(database, `todos/${id}`);
        update(todoRef, { text: newText });
    };

    // Function to move the todo item up
    const moveTodoUp = (index) => {
        if (index === 0) return; // Already at the top
        const reorderedTodos = [...todos];
        [reorderedTodos[index - 1], reorderedTodos[index]] = [reorderedTodos[index], reorderedTodos[index - 1]];

        reorderedTodos.forEach((todo, idx) => {
            const todoRef = ref(database, `todos/${todo.id}`);
            update(todoRef, { order: idx });
        });

        setTodos(reorderedTodos);
    };

    // Function to move the todo item down
    const moveTodoDown = (index) => {
        if (index === todos.length - 1) return; // Already at the bottom
        const reorderedTodos = [...todos];
        [reorderedTodos[index], reorderedTodos[index + 1]] = [reorderedTodos[index + 1], reorderedTodos[index]];

        reorderedTodos.forEach((todo, idx) => {
            const todoRef = ref(database, `todos/${todo.id}`);
            update(todoRef, { order: idx });
        });

        setTodos(reorderedTodos);
    };

    // Calculate the progress of completed tasks
    const completedTasksCount = todos.filter(todo => todo.completed).length;
    const totalTasksCount = todos.length;
    const completedPercentage = totalTasksCount === 0 ? 0 : Math.round((completedTasksCount / totalTasksCount) * 100);

    return (
        <div className="" style={{ backgroundColor: colors.background, minHeight: '100vh', paddingTop: '20px' }}>
            <div className='container'>
                <h1 className="text-center mb-4" style={{ color: colors.text }}>TodoWithDemi</h1>
                
                {/* Progress bar for completed tasks */}
                <div className="progress mb-4" style={{ height: '20px', maxWidth: '600px', margin: '0 auto' }}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${completedPercentage}%` }}
                        aria-valuenow={completedPercentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        {completedPercentage}% Completed
                    </div>
                </div>

                <div className="row justify-content-center mb-4">
                    <div className="col-12 col-md-8 col-lg-6 d-flex">
                        <input
                            type="text"
                            placeholder="Add a new todo"
                            value={newTodo}
                            onChange={(e) => setNewTodo(e.target.value)}
                            className="form-control me-2"
                        />
                        <button className="btn btn-success" onClick={handleAddTodo}><i className="bi bi-plus"></i></button>
                    </div>
                </div>

                {/* Apply auto-animate to this container */}
                <div ref={parentRef} className="mx-auto" style={{ maxWidth: '600px' }}>
                    {todos.map((todo, index) => (
                        <div key={todo.id} className={`card mb-3 ${todo.completed ? 'bg-light text-decoration-line-through' : ''}`}>
                            <div className="card-body d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    checked={todo.completed}
                                    onChange={() => handleToggleComplete(todo.id, todo.completed)}
                                    className="form-check-input me-3"
                                />
                                <input
    type="text"
    defaultValue={todo.text}
    className={`form-control hide-blue-ring me-3 ${todo.completed ? 'text-decoration-line-through' : ''}`}
    readOnly={todo.completed}
    onBlur={(e) => handleUpdateTodo(todo.id, e.target.value)}
    style={{ 
        backgroundColor: todo.completed ? '#f8f9fa' : '#fff', 
        border: 'none' 
    }}
/>
                                <div className="ms-auto d-flex align-items-center">
                                    {/* Up and down arrows */}
                                    <button
                                        className="btn btn-light btn-sm me-1"
                                        onClick={() => moveTodoUp(index)}
                                        disabled={index === 0} // Disable if it's the first item
                                    >
                                        <i className="bi bi-arrow-up"></i>
                                    </button>
                                    <button
                                        className="btn btn-light btn-sm me-1"
                                        onClick={() => moveTodoDown(index)}
                                        disabled={index === todos.length - 1} // Disable if it's the last item
                                    >
                                        <i className="bi bi-arrow-down"></i>
                                    </button>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteTodo(todo.id)}><i className="bi bi-trash3"></i></button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TodoWithDemi;
