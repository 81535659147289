import React from 'react';
import { useColors } from './ColorContext';

const BottomFooter = () => {
  const colors = useColors();


  return (
    <div>
      <style jsx>
        {`
        ins:hover {
          color: ${colors.secondary} !important;
        }
        `}
      </style>
      <footer className="text-center p-4" style={{ backgroundColor: colors.primary, color: colors.background }}>
      <div className="container">
        <div className="mt-3">
          {/* 1. Contact Information */}
          <p className="mb-0">
            Contact us:{' '}
            <ins href="mailto:support@wiredwaiter.com" style={{ color: colors.background, textDecoration: 'none' }}>
              support@wiredwaiter.com
            </ins>
          </p>

          {/* 2. Social Media Icons */}
          <div className="social-icons mt-3">
            <a href="https://www.facebook.com/wiredwaiter" target="_blank" rel="noopener noreferrer" className="me-3">
              <ins className="bi bi-facebook" style={{ color: colors.background, fontSize: '18px' }}></ins>
            </a>
            <a href="https://twitter.com/wiredwaiter" target="_blank" rel="noopener noreferrer" className="me-3">
              <ins className="bi bi-twitter-x" style={{ color: colors.background, fontSize: '18px' }}></ins>
            </a>
            <a href="https://www.instagram.com/wiredwaiter" target="_blank" rel="noopener noreferrer" className="me-3">
              <ins className="bi bi-instagram" style={{ color: colors.background, fontSize: '18px' }}></ins>
            </a>
            <a href="https://www.linkedin.com/company/wiredwaiter" target="_blank" rel="noopener noreferrer" className="me-3">
              <ins className="bi bi-linkedin" style={{ color: colors.background, fontSize: '18px' }}></ins>
            </a>
          </div>
        </div>
        <div className="mt-3">
          {/* 3. Terms of Service and Privacy Policy */}
          <ins href="/terms-of-service" className="me-3" style={{ color: colors.background }}>
            Terms of Service
          </ins>
          <ins href="/privacy-policy" style={{ color: colors.background }}>
            Privacy Policy
          </ins>
        </div>
        <div className="mt-3">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} Wired Waiter. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    </div>
  );
};

export default BottomFooter;
