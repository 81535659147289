import React from 'react';
import { useColors } from './ColorContext';

const NotFoundPage = () => {
    const colors = useColors();

    return (
        <div style={{ backgroundColor: colors.background }}>
                <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                        <div className="card" style={{ width: "40rem" }}>
                                <div className="card-body">
                                        <h1 className="card-title" style={{ fontWeight:"bold", fontFamily: "Outfit", color: colors.primary, textAlign: "center"}}>
                                        404
                                        </h1>
                                        <h2 style={{ fontFamily: "Outfit", color: colors.black, textAlign: "center"}}>It seems the page you are looking for has gone missing!</h2>
                                        <div className="text-center">
                                            <button 
                                                            className="btn"
                                                            style={{ backgroundColor: colors.primary, color: colors.background, marginTop: "10px", fontFamily: "Outfit"}}
                                                            onClick={() => window.location.replace("/")}
                                                            >
                                                            Go back to the home page
                                            </button>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
    );
};

export default NotFoundPage;
