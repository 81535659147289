import React, {useEffect} from "react";
import { useColors } from "./ColorContext";
import NavComponent from "./NavComponent";
import BottomMobileNav from './BottomMobileNav';
import { useNavigate } from "react-router-dom";
import HomeComponent1 from './HomeComponent1';
import HomeComponent2 from './HomeComponent2';
import HomeComponent3 from './HomeComponent3';
import "./styles.css";

const HomePage = () => {

  useEffect(() => {
    document.title = "Wired Waiter | Your Digital Dining Companion";
  }, []);

  const colors = useColors();
  const navigate = useNavigate();

  return (
    <div>
      <NavComponent />

      {/* Bootstrap container */}
      <div className="" style={{ paddingTop:"70px" }}>
      <HomeComponent1 />
      <HomeComponent2 />
      <HomeComponent3 />
      </div>
      <BottomMobileNav />
    </div>
  );
};

export default HomePage;
