import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, googleProvider } from "../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useColors } from "./ColorContext";

const LoginPage = () => {

  useEffect(() => {
    document.title = "Login | Wired Waiter";
  }, []);

  const colors = useColors();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const LogIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      const urlParams = new URLSearchParams(location.search);
      const redirectUrl = urlParams.get('redirectUrl');

      // Check if user is logged in
      const user = auth.currentUser;
      if (user) {
        // User is logged in, redirect to the specified URL or a default route
        if (redirectUrl) {
          navigate(`/${redirectUrl}`, { replace: true }); // Replaces current history entry
        } else {
          navigate('/', { replace: true }); // Default redirect URL if no redirectUrl specified
        }
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      const urlParams = new URLSearchParams(location.search);
      const redirectUrl = urlParams.get('redirectUrl');

      // Check if user is logged in
      const user = auth.currentUser;
      if (user) {
        // User is logged in, redirect to the specified URL or a default route
        if (redirectUrl) {
          navigate(`/${redirectUrl}`, { replace: true }); // Replaces current history entry
        } else {
          navigate('/', { replace: true }); // Default redirect URL if no redirectUrl specified
        }
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const handleDismiss = () => {
    setErrorMessage("");
  };

  return (
    <div style={{ backgroundColor: colors.background }}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <div className="card" style={{ width: "40rem", border: 'none' }}>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src="full-logo-white-bg.png"
                height="90"
                className="float-right"
              />
            </div>
            <h5
              className="card-title"
              style={{
                fontFamily: "Outfit",
                color: colors.black,
                textAlign: "center",
                fontSize: "30px",
              }}
            >
              Login to your account
            </h5>
            {errorMessage && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                {errorMessage}
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleDismiss}
                ></button>
              </div>
            )}
            <div>
              <div
                className="mb-3"
                style={{ fontFamily: "Outfit", color: colors.black }}
              >
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div
                  className="invalid-feedback"
                  style={{ fontFamily: "Outfit" }}
                >
                  Please enter a valid email address.
                </div>
              </div>
              <div
                className="mb-3"
                style={{ fontFamily: "Outfit", color: colors.black }}
              >
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  className="invalid-feedback"
                  style={{ fontFamily: "Outfit" }}
                >
                  Please enter a password.
                </div>
              </div>
              <center>
                <button
                  type="submit"
                  className="btn btn-lg"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: colors.primary,
                    color: colors.background,
                    fontFamily: "Outfit",
                  }}
                  onClick={LogIn}
                >
                  LOGIN
                </button>
              </center>

              <div
                className="my-3 border-top text-center"
                style={{ fontFamily: "Outfit" }}
              >
                <span className="px-3">or login with</span>
              </div>
              <div
                className="d-flex justify-content-center gap-3"
                style={{ fontFamily: "Outfit" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={signInWithGoogle}
                >
                  <i class="bi bi-google me-1"></i> Google
                </button>
              </div>
            </div>
            <div
              className="my-3 text-center"
              style={{ fontFamily: "Outfit" }}
            >
              <div>
                Forgot your password?
                <a
                  href="/forgot-password"
                  style={{ color: colors.secondary, textDecoration: "none" }}
                >
                  {" "}
                  Reset Password
                </a>
              </div>
              <div>
                Don't have an account?
                <a
                  href="/signup"
                  style={{ color: colors.secondary, textDecoration: "none" }}
                >
                  {" "}
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
