import React from "react";
import homescreenimage3 from './Images/homescreen3.jpeg';
import { useColors } from './ColorContext';

const HomeComponent3 = () => {
  const colors = useColors();

  return (

    <div style={{ backgroundColor: colors.background }}>
  <br />
  <div className="container">
    <div className="row align-items-center">
      <div className="col-sm order-0 order-sm-0">
      <h2 style={{ fontFamily: 'Boogaloo', color: colors.secondary, textAlign: 'center' }}>
      Are You a Restaurant Owner?
        </h2>
        <div className="d-grid justify-content-center align-items-center">
        </div>
        <p style={{ fontFamily: "Outfit", color: colors.primary, textAlign: "center" }}>
        From restaurants to food trucks, cafes to bakeries, and everything in between, our platform offers a comprehensive solution beyond mere ordering. With our fully cloud-based system, manage orders seamlessly from any smart device with an internet connection. Enhance and simplify customer interactions with your establishment, while boosting business growth and maximizing revenue streams.
        </p>
        <div className="d-grid justify-content-center align-items-center">
        </div>
        <div className="text-center mb-5">
          <a type="button" className="btn mx-1" href="/search" style={{ fontFamily: "Outfit", backgroundColor: colors.primary, color: colors.background, fontWeight: 'bold' }}>
            List Your Business
          </a>
          <a type="button" className="btn mx-1" href="/why-us" style={{ fontFamily: "Outfit", backgroundColor: colors.background, color: colors.primary, fontWeight: 'bold' }}>
            Learn More
          </a>
        </div>
      </div>
      <div className="col-sm order-1 order-sm-1">
      <img style={{ borderRadius: '15px 50px 30px' }} src={homescreenimage3} className="img-fluid" alt="Pizza with basil leaves" />
        <br />
        <br />
      </div>
    </div>
  </div>
</div>

  );
};

export default HomeComponent3;
